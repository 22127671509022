exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-manage-subscriptions-js": () => import("./../../../src/pages/manage-subscriptions.js" /* webpackChunkName: "component---src-pages-manage-subscriptions-js" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-prisms-js": () => import("./../../../src/pages/prisms.js" /* webpackChunkName: "component---src-pages-prisms-js" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-subscribe-to-updates-js": () => import("./../../../src/pages/subscribe-to-updates.js" /* webpackChunkName: "component---src-pages-subscribe-to-updates-js" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-4-reasons-prismatic-is-attending-saastr-annual-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/4-reasons-prismatic-is-attending-saastr-annual.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-4-reasons-prismatic-is-attending-saastr-annual-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-alloy-automation-vs-prismatic-for-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/alloy-automation-vs-prismatic-for-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-alloy-automation-vs-prismatic-for-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-anatomy-of-an-api-connector-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/anatomy-of-an-api-connector.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-anatomy-of-an-api-connector-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-anatomy-webhook-http-request-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/anatomy-webhook-http-request.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-anatomy-webhook-http-request-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-announcing-prismatic-general-availability-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/announcing-prismatic-general-availability.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-announcing-prismatic-general-availability-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-announcing-private-beta-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/announcing-private-beta.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-announcing-private-beta-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-api-connectors-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/api-connectors.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-api-connectors-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-api-integration-platform-embedded-integration-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/api-integration-platform-embedded-integration.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-api-integration-platform-embedded-integration-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-api-less-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/api-less-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-api-less-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-api-vs-integration-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/api-vs-integration.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-api-vs-integration-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-apis-by-access-type-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/apis-by-access-type.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-apis-by-access-type-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-april-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/april-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-april-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-april-2022-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/april-2022-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-april-2022-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-april-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/april-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-april-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-august-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/august-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-august-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-august-2023-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/august-2023-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-august-2023-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-august-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/august-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-august-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-b-2-b-saas-integrations-working-with-the-messy-middle-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/b2b-saas-integrations-working-with-the-messy-middle.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-b-2-b-saas-integrations-working-with-the-messy-middle-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-b-2-b-signs-you-need-integration-platform-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/b2b-signs-you-need-integration-platform.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-b-2-b-signs-you-need-integration-platform-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-b-2-b-software-companies-gain-from-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/b2b-software-companies-gain-from-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-b-2-b-software-companies-gain-from-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-benefits-of-embedded-integration-marketplace-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/benefits-of-embedded-integration-marketplace.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-benefits-of-embedded-integration-marketplace-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-building-an-integration-isnt-the-hard-part-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/building-an-integration-isnt-the-hard-part.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-building-an-integration-isnt-the-hard-part-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-can-prismatic-handle-your-challenging-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/can-prismatic-handle-your-challenging-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-can-prismatic-handle-your-challenging-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-common-b-2-b-saas-integration-patterns-when-to-use-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/common-b2b-saas-integration-patterns-when-to-use.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-common-b-2-b-saas-integration-patterns-when-to-use-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-connect-to-aws-resources-with-ssh-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/connect-to-aws-resources-with-ssh.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-connect-to-aws-resources-with-ssh-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-crm-integration-for-b-2-b-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/crm-integration-for-b2b-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-crm-integration-for-b-2-b-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-cyclr-vs-prismatic-for-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/cyclr-vs-prismatic-for-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-cyclr-vs-prismatic-for-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-december-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/december-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-december-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-december-2023-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/december-2023-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-december-2023-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-different-apis-and-how-they-work-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/different-apis-and-how-they-work.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-different-apis-and-how-they-work-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-embedded-integration-for-b-2-b-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/embedded-integration-for-b2b-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-embedded-integration-for-b-2-b-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-embedded-integration-platform-benefits-for-saas-companies-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/embedded-integration-platform-benefits-for-saas-companies.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-embedded-integration-platform-benefits-for-saas-companies-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-embedded-integrations-on-uptech-report-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/embedded-integrations-on-uptech-report.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-embedded-integrations-on-uptech-report-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-embedded-ipaas-vs-enterprise-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/embedded-ipaas-vs-enterprise-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-embedded-ipaas-vs-enterprise-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-erp-integration-for-b-2-b-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/erp-integration-for-b2b-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-erp-integration-for-b-2-b-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-fall-2022-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/fall-2022-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-fall-2022-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-fall-2023-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/fall-2023-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-fall-2023-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-february-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/february-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-february-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-february-2022-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/february-2022-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-february-2022-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-february-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/february-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-february-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-graphql-api-for-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/graphql-api-for-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-graphql-api-for-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-secure-webhook-endpoints-hmac-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-secure-webhook-endpoints-hmac.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-secure-webhook-endpoints-hmac-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-announce-new-b-2-b-saas-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-announce-new-b2b-saas-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-announce-new-b-2-b-saas-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-build-an-integration-to-a-third-party-app-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-build-an-integration-to-a-third-party-app.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-build-an-integration-to-a-third-party-app-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-build-great-ux-for-b-2-b-saas-api-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-build-great-ux-for-b2b-saas-api.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-build-great-ux-for-b-2-b-saas-api-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-choose-embedded-ipaas-for-your-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-choose-embedded-ipaas-for-your-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-choose-embedded-ipaas-for-your-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-create-outcome-driven-roadmaps-with-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-create-outcome-driven-roadmaps-with-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-create-outcome-driven-roadmaps-with-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-gather-integration-requirements-questions-and-tips-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-gather-integration-requirements-questions-and-tips.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-gather-integration-requirements-questions-and-tips-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-price-your-b-2-b-saas-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-price-your-b2b-saas-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-price-your-b-2-b-saas-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-prioritize-integrations-for-your-b-2-b-saas-product-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-prioritize-integrations-for-your-b2b-saas-product.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-prioritize-integrations-for-your-b-2-b-saas-product-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-scale-integrations-for-your-b-2-b-saas-product-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-scale-integrations-for-your-b2b-saas-product.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-scale-integrations-for-your-b-2-b-saas-product-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-use-versioning-in-prismatic-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/how-to-use-versioning-in-prismatic.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-how-to-use-versioning-in-prismatic-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-hris-integration-for-b-2-b-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/hris-integration-for-b2b-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-hris-integration-for-b-2-b-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-http-methods-saas-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/http-methods-saas-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-http-methods-saas-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-importance-saas-channel-partnerships-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/importance-saas-channel-partnerships.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-importance-saas-channel-partnerships-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-multiple-versions-app-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrating-multiple-versions-app.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-multiple-versions-app-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-with-google-apis-tips-and-tricks-part-1-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrating-with-google-apis-tips-and-tricks-part-1.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-with-google-apis-tips-and-tricks-part-1-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-with-google-apis-tips-and-tricks-part-2-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrating-with-google-apis-tips-and-tricks-part-2.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-with-google-apis-tips-and-tricks-part-2-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-with-salesforce-apis-tips-and-tricks-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrating-with-salesforce-apis-tips-and-tricks.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-with-salesforce-apis-tips-and-tricks-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-with-slack-apis-tips-and-tricks-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrating-with-slack-apis-tips-and-tricks.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrating-with-slack-apis-tips-and-tricks-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integration-automation-platform-or-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integration-automation-platform-or-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integration-automation-platform-or-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integration-marketplace-vs-embedded-integration-marketplace-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integration-marketplace-vs-embedded-integration-marketplace.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integration-marketplace-vs-embedded-integration-marketplace-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integration-platforms-for-b-2-b-saas-companies-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integration-platforms-for-b2b-saas-companies.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integration-platforms-for-b-2-b-saas-companies-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integration-support-strategies-b-2-b-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integration-support-strategies-b2b-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integration-support-strategies-b-2-b-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integration-trends-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integration-trends.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integration-trends-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrations-enhance-product-led-growth-b-2-b-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrations-enhance-product-led-growth-b2b-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrations-enhance-product-led-growth-b-2-b-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrations-shouldnt-be-so-hard-an-engineers-perspective-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrations-shouldnt-be-so-hard-an-engineers-perspective.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrations-shouldnt-be-so-hard-an-engineers-perspective-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrations-shouldnt-be-so-hard-an-organization-wide-problem-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrations-shouldnt-be-so-hard-an-organization-wide-problem.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrations-shouldnt-be-so-hard-an-organization-wide-problem-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-integrations-shouldnt-be-so-hard-and-why-it-matters-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/integrations-shouldnt-be-so-hard-and-why-it-matters.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-integrations-shouldnt-be-so-hard-and-why-it-matters-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-code-native-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/introducing-code-native-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-code-native-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-component-actions-in-code-native-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/introducing-component-actions-in-code-native.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-component-actions-in-code-native-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-github-actions-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/introducing-github-actions.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-github-actions-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-new-designer-experience-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/introducing-new-designer-experience.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-new-designer-experience-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-prismatics-on-prem-agent-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/introducing-prismatics-on-prem-agent.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-introducing-prismatics-on-prem-agent-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-january-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/january-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-january-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-january-2022-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/january-2022-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-january-2022-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-january-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/january-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-january-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-july-2023-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/july-2023-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-july-2023-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-july-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/july-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-july-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-june-2022-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/june-2022-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-june-2022-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-june-2023-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/june-2023-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-june-2023-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-june-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/june-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-june-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-loop-over-paginated-api-for-integration-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/loop-over-paginated-api-for-integration.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-loop-over-paginated-api-for-integration-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-making-best-auth-decisions-saas-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/making-best-auth-decisions-saas-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-making-best-auth-decisions-saas-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-march-2022-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/march-2022-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-march-2022-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-march-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/march-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-march-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-may-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/may-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-may-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-may-2022-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/may-2022-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-may-2022-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-may-2023-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/may-2023-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-may-2023-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-may-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/may-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-may-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-measure-integration-roi-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/measure-integration-roi.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-measure-integration-roi-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-measure-integration-success-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/measure-integration-success.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-measure-integration-success-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-mergers-and-company-growth-ft-michael-zuercher-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/mergers-and-company-growth-ft-michael-zuercher.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-mergers-and-company-growth-ft-michael-zuercher-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-mime-types-say-http-messages-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/mime-types-say-http-messages.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-mime-types-say-http-messages-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-multiple-similar-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/multiple-similar-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-multiple-similar-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-november-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/november-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-november-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-november-2023-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/november-2023-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-november-2023-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-october-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/october-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-october-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-october-2023-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/october-2023-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-october-2023-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-optimizing-for-event-driven-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/optimizing-for-event-driven-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-optimizing-for-event-driven-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-paragon-prismatic-building-integrations-in-code-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/paragon-prismatic-building-integrations-in-code.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-paragon-prismatic-building-integrations-in-code-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-paragon-vs-prismatic-for-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/paragon-vs-prismatic-for-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-paragon-vs-prismatic-for-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-announces-9-million-in-funding-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/press-release-prismatic-announces-9-million-in-funding.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-announces-9-million-in-funding-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-introduces-code-native-building-experience-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/press-release-prismatic-introduces-code-native-building-experience.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-introduces-code-native-building-experience-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-launches-embedded-integration-designer-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/press-release-prismatic-launches-embedded-integration-designer.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-launches-embedded-integration-designer-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-launches-embedded-integration-platform-for-b-2-b-software-companies-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/press-release-prismatic-launches-embedded-integration-platform-for-b2b-software-companies.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-launches-embedded-integration-platform-for-b-2-b-software-companies-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-raises-22-million-in-series-b-funding-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/press-release-prismatic-raises-22-million-in-series-b-funding.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-raises-22-million-in-series-b-funding-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-unveils-new-integration-designer-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/press-release-prismatic-unveils-new-integration-designer.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-prismatic-unveils-new-integration-designer-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-seed-raise-announcement-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/press-release-seed-raise-announcement.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-press-release-seed-raise-announcement-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-adds-vp-of-engineering-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/prismatic-adds-vp-of-engineering.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-adds-vp-of-engineering-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-earns-g-2-2024-best-software-award-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/prismatic-earns-g2-2024-best-software-award.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-earns-g-2-2024-best-software-award-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-first-embedded-ipaas-on-aws-govcloud-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/prismatic-first-embedded-ipaas-on-aws-govcloud.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-first-embedded-ipaas-on-aws-govcloud-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-g-2-embedded-integration-platforms-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/prismatic-g2-embedded-integration-platforms-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-g-2-embedded-integration-platforms-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-g-2-ipaas-winter-2022-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/prismatic-g2-ipaas-winter-2022.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-g-2-ipaas-winter-2022-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-hires-first-chief-revenue-officer-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/prismatic-hires-first-chief-revenue-officer.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-hires-first-chief-revenue-officer-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-launching-a-product-in-a-pandemic-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/prismatic-launching-a-product-in-a-pandemic.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-launching-a-product-in-a-pandemic-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-wins-2024-proddy-award-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/prismatic-wins-2024-proddy-award.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-prismatic-wins-2024-proddy-award-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-react-and-typescript-at-prismatic-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/react-and-typescript-at-prismatic.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-react-and-typescript-at-prismatic-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-real-world-integrations-for-b-2-b-saas-companies-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/real-world-integrations-for-b2b-saas-companies.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-real-world-integrations-for-b-2-b-saas-companies-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-reducing-integration-workload-for-your-engineering-team-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/reducing-integration-workload-for-your-engineering-team.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-reducing-integration-workload-for-your-engineering-team-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-reflections-on-invisory-integration-hackathon-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/reflections-on-invisory-integration-hackathon.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-reflections-on-invisory-integration-hackathon-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-rest-api-for-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/rest-api-for-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-rest-api-for-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-saas-integration-challenges-for-b-2-b-saas-companies-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/saas-integration-challenges-for-b2b-saas-companies.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-saas-integration-challenges-for-b-2-b-saas-companies-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-saas-integration-platform-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/saas-integration-platform.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-saas-integration-platform-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-saas-integration-security-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/saas-integration-security.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-saas-integration-security-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-saas-marketplace-vs-integration-marketplace-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/saas-marketplace-vs-integration-marketplace.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-saas-marketplace-vs-integration-marketplace-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-saas-technology-partnerships-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/saas-technology-partnerships.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-saas-technology-partnerships-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-say-yes-more-often-with-prismatic-embedded-integration-designer-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/say-yes-more-often-with-prismatic-embedded-integration-designer.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-say-yes-more-often-with-prismatic-embedded-integration-designer-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-september-2021-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/september-2021-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-september-2021-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-september-2024-news-updates-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/september-2024-news-updates.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-september-2024-news-updates-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-silicon-prairie-news-interview-with-michael-zuercher-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/silicon-prairie-news-interview-with-michael-zuercher.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-silicon-prairie-news-interview-with-michael-zuercher-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-simpler-more-flexible-authentication-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/simpler-more-flexible-authentication.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-simpler-more-flexible-authentication-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-sioux-falls-b-2-b-software-integration-launches-today-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/sioux-falls-b2b-software-integration-launches-today.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-sioux-falls-b-2-b-software-integration-launches-today-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-six-things-b-2-b-saas-integration-marketplace-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/six-things-b2b-saas-integration-marketplace.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-six-things-b-2-b-saas-integration-marketplace-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-soap-api-for-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/soap-api-for-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-soap-api-for-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-soc-2-type-1-audit-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/soc-2-type-1-audit.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-soc-2-type-1-audit-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-soc-2-type-2-audit-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/soc-2-type-2-audit.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-soc-2-type-2-audit-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-software-integrations-on-constant-variables-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/software-integrations-on-constant-variables.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-software-integrations-on-constant-variables-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-speeding-up-integrations-for-faster-customer-onboarding-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/speeding-up-integrations-for-faster-customer-onboarding.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-speeding-up-integrations-for-faster-customer-onboarding-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-spring-2023-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/spring-2023-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-spring-2023-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-spring-2024-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/spring-2024-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-spring-2024-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-startup-founders-second-act-launches-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/startup-founders-second-act-launches.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-startup-founders-second-act-launches-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-stop-b-2-b-saas-integration-support-ping-pong-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/stop-b2b-saas-integration-support-ping-pong.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-stop-b-2-b-saas-integration-support-ping-pong-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-summer-2022-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/summer-2022-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-summer-2022-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-summer-2023-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/summer-2023-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-summer-2023-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-summer-2024-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/summer-2024-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-summer-2024-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-test-test-test-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/test-test-test.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-test-test-test-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-business-case-for-an-integration-platform-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/the-business-case-for-an-integration-platform.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-business-case-for-an-integration-platform-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-the-new-person-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/the-new-person.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-the-new-person-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-third-party-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/third-party-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-third-party-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-track-integration-deployments-from-trigger-flows-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/track-integration-deployments-from-trigger-flows.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-track-integration-deployments-from-trigger-flows-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-traditional-ipaas-doesnt-work-for-software-companies-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/traditional-ipaas-doesnt-work-for-software-companies.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-traditional-ipaas-doesnt-work-for-software-companies-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-transfer-protocols-transport-languages-saas-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/transfer-protocols-transport-languages-saas-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-transfer-protocols-transport-languages-saas-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-tray-vs-prismatic-for-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/tray-vs-prismatic-for-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-tray-vs-prismatic-for-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-unified-api-vs-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/unified-api-vs-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-unified-api-vs-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-user-experience-b-2-b-saas-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/user-experience-b2b-saas-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-user-experience-b-2-b-saas-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-wasting-time-on-infrastructure-for-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/wasting-time-on-infrastructure-for-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-wasting-time-on-infrastructure-for-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-are-webhooks-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/what-are-webhooks.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-are-webhooks-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-a-saas-partner-program-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/what-is-a-saas-partner-program.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-a-saas-partner-program-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-an-api-integration-example-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/what-is-an-api-integration-example.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-an-api-integration-example-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-embedded-ipaas-g-2-prismatic-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/what-is-embedded-ipaas-g2-prismatic.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-embedded-ipaas-g-2-prismatic-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/what-is-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-is-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-what-you-should-know-before-you-build-a-soap-integration-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/what-you-should-know-before-you-build-a-soap-integration.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-what-you-should-know-before-you-build-a-soap-integration-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-whats-the-best-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/whats-the-best-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-whats-the-best-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-when-use-scheduled-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/when-use-scheduled-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-when-use-scheduled-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-when-use-synchronous-integrations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/when-use-synchronous-integrations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-when-use-synchronous-integrations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-white-label-ipaas-for-b-2-b-saas-companies-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/white-label-ipaas-for-b2b-saas-companies.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-white-label-ipaas-for-b-2-b-saas-companies-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-why-dev-teams-are-incorporating-saas-tools-as-app-components-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/why-dev-teams-are-incorporating-saas-tools-as-app-components.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-why-dev-teams-are-incorporating-saas-tools-as-app-components-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-why-graph-ql-is-faster-than-rest-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/why-GraphQL-is-faster-than-REST.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-why-graph-ql-is-faster-than-rest-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-why-prismatic-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/why-prismatic.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-why-prismatic-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-why-we-moved-from-lambda-to-ecs-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/why-we-moved-from-lambda-to-ecs.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-why-we-moved-from-lambda-to-ecs-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-winter-2023-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/winter-2023-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-winter-2023-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-winter-2024-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/winter-2024-g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-winter-2024-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-workato-vs-prismatic-for-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/workato-vs-prismatic-for-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-workato-vs-prismatic-for-embedded-ipaas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-writing-triggers-to-handle-third-party-needs-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/writing-triggers-to-handle-third-party-needs.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-writing-triggers-to-handle-third-party-needs-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-xml-rpc-api-for-saas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/xml-rpc-api-for-saas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-xml-rpc-api-for-saas-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-xml-vs-json-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/xml-vs-json.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-xml-vs-json-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-zapier-vs-prismatic-for-embedded-ipaas-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/builds/prismatic-io/marketing/website/content/blog/zapier-vs-prismatic-for-embedded-ipaas.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-zapier-vs-prismatic-for-embedded-ipaas-mdx" */),
  "component---src-templates-connector-tsx": () => import("./../../../src/templates/Connector.tsx" /* webpackChunkName: "component---src-templates-connector-tsx" */),
  "component---src-templates-default-tsx-content-file-path-content-customers-hatch-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/customers/hatch.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-customers-hatch-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-customers-raven-industries-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/customers/raven-industries.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-customers-raven-industries-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-customers-sisu-software-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/customers/sisu-software.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-customers-sisu-software-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-events-saastock-dublin-2024-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/events/saastock-dublin-2024.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-events-saastock-dublin-2024-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-events-webinar-code-native-integrations-2024-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/events/webinar-code-native-integrations-2024.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-events-webinar-code-native-integrations-2024-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-events-webinar-integration-marketplace-2024-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/events/webinar-integration-marketplace-2024.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-events-webinar-integration-marketplace-2024-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-events-webinar-key-product-features-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/events/webinar-key-product-features.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-events-webinar-key-product-features-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-events-webinar-yoti-accelerate-2024-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/events/webinar-yoti-accelerate-2024.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-events-webinar-yoti-accelerate-2024-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-form-contact-internal-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/form/contact-internal.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-form-contact-internal-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-form-contact-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/form/contact.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-form-contact-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-form-contact-startup-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/form/contact-startup.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-form-contact-startup-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-form-request-a-connector-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/form/request-a-connector.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-form-request-a-connector-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-form-request-a-demo-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/form/request-a-demo.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-form-request-a-demo-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-form-sign-up-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/form/sign-up.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-form-sign-up-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-alloy-embedded-alternative-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/alloy-embedded-alternative.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-alloy-embedded-alternative-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-apideck-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/alternative-to-apideck.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-apideck-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-appmixer-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/alternative-to-appmixer.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-appmixer-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-blendr-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/alternative-to-blendr.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-blendr-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-cyclr-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/alternative-to-cyclr.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-cyclr-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-paragon-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/alternative-to-paragon.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-alternative-to-paragon-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-alternative-tray-embedded-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/alternative-tray-embedded.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-alternative-tray-embedded-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-b-2-b-integration-platform-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/b2b-integration-platform.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-b-2-b-integration-platform-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-best-api-integration-platform-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/best-api-integration-platform.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-best-api-integration-platform-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-best-embedded-integration-platform-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/best-embedded-integration-platform.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-best-embedded-integration-platform-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-best-integration-platform-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/best-integration-platform.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-best-integration-platform-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-build-my-integration-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/build-my-integration.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-build-my-integration-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-embedded-ipaas-for-b-2-b-saas-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/embedded-ipaas-for-b2b-saas.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-embedded-ipaas-for-b-2-b-saas-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-g-2-report-embedded-integration-platforms-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/g2-report-embedded-integration-platforms.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-g-2-report-embedded-integration-platforms-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-g-2-reviewer-notes-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/g2-reviewer-notes.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-g-2-reviewer-notes-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-integration-security-guide-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/integration-security-guide.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-integration-security-guide-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-integration-specification-template-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/integration-specification-template.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-integration-specification-template-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-native-integration-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/native-integration.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-native-integration-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-roi-calculator-detailed-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/roi-calculator-detailed.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-roi-calculator-detailed-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-roi-calculator-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/roi-calculator.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-roi-calculator-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-saas-integration-platform-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/saas-integration-platform.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-saas-integration-platform-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-six-signs-you-need-embedded-integration-platform-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/six-signs-you-need-embedded-integration-platform.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-six-signs-you-need-embedded-integration-platform-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-white-label-integration-platform-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/white-label-integration-platform.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-white-label-integration-platform-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-white-label-ipaas-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/white-label-ipaas.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-white-label-ipaas-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-landing-workato-alternative-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/landing/workato-alternative.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-landing-workato-alternative-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-pages-components-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/pages/components.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-pages-components-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-resources-api-integrations-a-beginners-guide-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/resources/api-integrations-a-beginners-guide.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-resources-api-integrations-a-beginners-guide-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-resources-benefits-embedded-integration-platforms-for-saas-products-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/resources/benefits-embedded-integration-platforms-for-saas-products.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-resources-benefits-embedded-integration-platforms-for-saas-products-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-resources-build-vs-buy-adding-native-integrations-to-your-product-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/resources/build-vs-buy-adding-native-integrations-to-your-product.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-resources-build-vs-buy-adding-native-integrations-to-your-product-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-resources-integration-marketplace-for-b-2-b-saas-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/resources/integration-marketplace-for-b2b-saas.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-resources-integration-marketplace-for-b-2-b-saas-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-resources-scalable-integration-strategy-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/resources/scalable-integration-strategy.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-resources-scalable-integration-strategy-mdx" */),
  "component---src-templates-default-tsx-content-file-path-content-resources-why-prismatic-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/builds/prismatic-io/marketing/website/content/resources/why-prismatic.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-content-resources-why-prismatic-mdx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */),
  "component---src-templates-legal-post-index-js": () => import("./../../../src/templates/legal-post/index.js" /* webpackChunkName: "component---src-templates-legal-post-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-thank-you-tsx": () => import("./../../../src/templates/thank-you.tsx" /* webpackChunkName: "component---src-templates-thank-you-tsx" */)
}

